import { Pinia, Store } from '@/store/pinia-class-component';
import { fetchLatestVersion } from '@/api/mobile/AppInfoApi';

export function useAppStore() {
  return new AppStore();
}

@Store
class AppStore extends Pinia {
  private _isDialogOpen = false;

  get isDialogOpen(): boolean {
    return this._isDialogOpen;
  }

  toggleAppDownloadDialog(state: boolean) {
    this._isDialogOpen = state;
  }

  async getLatestVersion() {
    return fetchLatestVersion();
  }
}
