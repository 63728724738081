<template>
  <BaseModal :active="true" @closed="closeModal()">
    <template #title>App herunterladen</template>
    <template #default>
      <span class="text-black"
        >Verbessern Sie Ihr Erlebnis! Laden Sie jetzt unsere mobile App herunter
        und genießen Sie die neuesten Funktionen und Innovationen auf
        Knopfdruck</span
      >
      <div class="mt-6 flex items-center justify-center">
        <button
          class="rounded-full bg-primary-1 p-6 py-2 text-white hover:bg-primary-2"
          @click="downloadApp"
        >
          <span class="mdi mdi-download-circle pr-2"></span
          ><span>Download App</span>
        </button>
      </div>
    </template>
    <template #buttons>
      <button class="button" @click="closeModal">Abbrechen</button>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import BaseModal from '@/components/UI/Modal/BaseModal.vue';
import { useAppStore } from '@/store/mobile/AppStore';

const appStore = useAppStore();
const emit = defineEmits(['closeDialog']);
const closeModal = () => {
  emit('closeDialog');
};

const downloadApp = async () => {
  const { downloadLink } = await appStore.getLatestVersion();
  window.open(downloadLink, '_parent');
};
</script>
