import { generateUniqueId } from '@/helpers/uuidUtils';
import { entityTypes } from '@/enums/generic';
import { ContactPerson } from '@/models/types/ContactPerson';

/**
 * Any adjustments to the properties of this class
 * should be reflected in the android kotlin class {@see Building.kt}
 */

export class Building {
  public id: string;
  public businessEntityId?: string;
  public address: Address;
  public contactPerson: ContactPerson;
  public buildingSection: string;
  public entrance: string;
  public constructionYear?: number;
  public interiorWallType?: string;
  public exteriorWallType?: string;
  public floorType?: string;
  public hasMonumentProtection: boolean;
  public usageType: string;
  public isAccessible: boolean;
  public reasonNotAccessible?: string;
  public dateNotAccessible?: string;
  public note?: string;
  public type: string;

  constructor() {
    this.id = generateUniqueId();
    this.businessEntityId = undefined;
    this.address = {
      street: '',
      houseNumber: '',
      zipCode: '',
      city: '',
    };
    this.contactPerson = {
      name: '',
      phoneNumber: '',
      role: undefined,
      note: undefined,
    };
    this.buildingSection = '';
    this.entrance = '';
    this.hasMonumentProtection = false;
    this.usageType = '';
    this.isAccessible = true;
    this.reasonNotAccessible = undefined;
    this.dateNotAccessible = undefined;
    this.note = undefined;
    this.type = entityTypes.building;
  }

  get shortAddress() {
    return this.address.street + ' ' + this.address.houseNumber;
  }

  getParentId() {
    return this.businessEntityId;
  }

  getLabel() {
    if (this.address.street && this.address.houseNumber) {
      return this.address.street + ' ' + this.address.houseNumber;
    }
    return !this.address.street ? '' : this.address.street;
  }

  getIcon() {
    return 'fas fa-home';
  }
}
