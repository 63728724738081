import { entityTypes } from '@/enums/generic';
import { Building } from '@/models/Building';
import { Room } from '@/models/Room';
import { Roomgroup } from '@/models/Roomgroup';
import {
  GGRadiator,
  GRRadiator,
  GSRadiator,
  HWRadiator,
  PGRadiator,
  PPRadiator,
  Radiator,
  UnknownRadiator,
  PlaceholderRadiator,
} from '@/models/radiators/Radiator';
import { Transform, Type } from 'class-transformer';

import 'reflect-metadata';
import { DeviceClasses, DeviceFactory } from '@/models/devices/Device';
import { radiatorTypes } from '@/enums/radiator/generic';
import { InstallationPoint } from '@/models/installationPoint/InstallationPoint';
import { ContactPerson } from '@/models/types/ContactPerson';
import { PlantDataClasses, PlantFactory } from '@/models/plant/Plant';
import { InspectedBuilding } from '@/models/InspectedBuilding';

/**
 * Any adjustments to the properties of this class
 * should be reflected in the android kotlin class {@see BusinessEntity.kt}
 */
export class BusinessEntity {
  public id: string;
  public humanReadableId: string;
  public billingUnitReference: string;
  public externalId: string;
  public propertyId: string;
  public address: Address;
  public contactPerson?: ContactPerson;
  public type: string;
  public updatedAt?: string;

  @Type(() => Building)
  public buildings: Array<Building>;
  @Type(() => InspectedBuilding)
  public inspectedBuildings: Array<InspectedBuilding>;
  @Type(() => Room)
  public rooms: Array<Room>;
  @Type(() => Roomgroup)
  public roomGroups: Array<Roomgroup>;

  @Transform((deviceDuties) => {
    deviceDuties.value.forEach(function (device: any, index: number) {
      deviceDuties.value[index] = DeviceFactory.createDevice(device.deviceType);
      deviceDuties.value[index].updateProperties(device);
    });
    return deviceDuties.value;
  })
  public devices: Array<DeviceClasses>;
  @Type(() => InstallationPoint)
  public installationPoints: Array<InstallationPoint>;

  @Transform((plantDuties) => {
    plantDuties.value.forEach(function (plant: any, index: number) {
      plantDuties.value[index] = PlantFactory.createPlant(plant.plantType);
      plantDuties.value[index].updateProperties(plant);
    });
    return plantDuties.value;
  })
  public plants: Array<PlantDataClasses>;

  @Type(() => Radiator, {
    discriminator: {
      property: 'radiatorType',
      subTypes: [
        { value: PPRadiator, name: radiatorTypes.PP },
        { value: GSRadiator, name: radiatorTypes.GS },
        { value: HWRadiator, name: radiatorTypes.HW },
        { value: GGRadiator, name: radiatorTypes.GG },
        { value: GRRadiator, name: radiatorTypes.GR },
        { value: PGRadiator, name: radiatorTypes.PG },
        { value: UnknownRadiator, name: radiatorTypes.UNKNOWN },
        { value: PlaceholderRadiator, name: radiatorTypes.PlaceholderRadiator },
      ],
    },
  })
  public radiators: Array<Radiator>;

  constructor() {
    this.id = '';
    this.humanReadableId = '';
    this.billingUnitReference = '';
    this.externalId = '';
    this.propertyId = '';
    this.address = {
      street: '',
      houseNumber: '',
      zipCode: '',
      city: '',
    };
    this.contactPerson = undefined;
    this.updatedAt = undefined;
    this.type = entityTypes.businessEntity;
    this.buildings = [];
    this.inspectedBuildings = [];
    this.roomGroups = [];
    this.devices = [];
    this.plants = [];
    this.radiators = [];
    this.rooms = [];
    this.installationPoints = [];
  }

  getLabel() {
    return this.humanReadableId;
  }

  getIcon() {
    return 'fas fa-city';
  }

  getParentId() {
    return undefined;
  }
}
