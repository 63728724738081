import { Entity } from '@/models/types/Entity';
import { DifferenceChange } from 'microdiff';

export class OrderChanges {
  roomGroupProgress: Array<string>;
  hasChanges: boolean;
  transactions: { [key: string]: EntityTransaction };
  lastSaved?: string;

  constructor() {
    this.roomGroupProgress = [];
    this.hasChanges = false;
    this.transactions = {};
    this.lastSaved = undefined;
  }

  addRoomGroupProgress(roomGroupId: string) {
    this.hasChanges = true;
    if (this.roomGroupProgress.includes(roomGroupId)) return;
    this.roomGroupProgress.push(roomGroupId);
  }

  removeRoomGroupProgress(roomGroupId: string) {
    this.hasChanges = true;
    this.roomGroupProgress = this.roomGroupProgress.filter(
      (roomGroupProgress) => roomGroupProgress !== roomGroupId
    );
  }

  hasRoomGroupProgress(roomGroupId: string) {
    return this.roomGroupProgress.includes(roomGroupId);
  }

  upsertTransaction(
    entity: Entity,
    type: EntityTransactionType,
    diff: DifferenceChange[] = []
  ) {
    const oldDiff = this.transactions[entity.id]?.diff ?? [];
    const newDiff = diff.map((item) => {
      return new TransactionDiff(
        item.oldValue,
        item.value,
        item.path,
        item.type
      );
    });

    const mergedDiff = this.mergeDiffs(oldDiff, newDiff);

    this.transactions[entity.id] = new EntityTransaction(
      entity,
      type,
      mergedDiff
    );
  }

  mergeDiffs(oldDiff: TransactionDiff[], newDiff: TransactionDiff[]) {
    const mergedDiff = new Map();

    oldDiff.forEach((item) => {
      mergedDiff.set(item.path, item);
    });

    newDiff.forEach((item) => {
      const pathKey = item.path;
      if (mergedDiff.has(pathKey)) {
        const existingItem = mergedDiff.get(pathKey);
        item.oldValue = existingItem.oldValue;
      }
      mergedDiff.set(pathKey, item);
    });

    return Array.from(mergedDiff.values());
  }
}

export class TransactionDiff {
  oldValue: any;
  newValue: any;
  path: string;
  type: string;

  constructor(oldValue: any, newValue: any, path: any[], type: string) {
    this.oldValue = oldValue instanceof Object ? JSON.stringify(oldValue) : oldValue;
    this.newValue = newValue instanceof Object ? JSON.stringify(newValue) : newValue;
    this.path = path.join('.');
    this.type = type;
  }
}

export enum TransactionDiffType {
  CREATE = 'CREATE',
  CHANGE = 'CHANGE',
  REMOVE = 'REMOVE',
}

export class EntityTransaction {
  entity: Entity;
  entityType: string;
  entityId: string;
  type: EntityTransactionType;
  timestamp: string;
  diff: any;

  constructor(entity: Entity, type: EntityTransactionType, diff?: any) {
    this.entity = entity;
    this.entityType = entity.type;
    this.entityId = entity.id;
    this.type = type;
    this.diff = diff;
    this.timestamp = new Date().toISOString();
  }
}

export enum EntityTransactionType {
  DELETE_ENTITY = 'DELETE_ENTITY',
  UPDATE_ENTITY = 'UPDATE_ENTITY',
  CREATE_ENTITY = 'CREATE_ENTITY',
}
