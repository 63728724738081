<template>
  <div
    class="prose prose-neutral flex max-w-none flex-col bg-light-0 dark:prose-invert dark:bg-dark-0 md:flex-row"
  >
    <div
      class="flex-grow-1 fixed z-40 w-full basis-20 bg-light-1 shadow dark:bg-dark-1 dark:text-white md:relative md:w-auto md:basis-12"
    >
      <SideNavBar />
    </div>
    <div
      class="h-screen overflow-y-auto bg-light-0 dark:bg-dark-0 md:grow md:basis-0"
      :class="[{ 'px-8 ': currentRoute !== routesNames.DETAIL_VIEW }]"
    >
      <slot />
    </div>

    <div
      v-if="currentRoute === routesNames.DETAIL_VIEW"
      :class="[isSideBarOpen ? 'w-1/3' : 'w-0']"
      class="fixed bottom-0 z-20 flex h-1/2 justify-center rounded-3xl border-l align-middle transition-all dark:border-dark-2 md:relative md:bottom-0 md:right-0 md:mt-0 md:rounded-none"
    >
      <div
        class="side-menu-button border-y border-l bg-light-1 dark:border-dark-2 dark:bg-dark-1"
        data-testid="sideMenuButtonToggle"
        @click="toggleSideMenu"
      >
        <em
          class="mdi mdi-menu-open text-2xl"
          :class="[isSideBarOpen ? 'flip-horizontally' : '']"
        ></em>
      </div>
      <div
        data-testid="sideBar"
        :class="[isSideBarOpen ? 'md:w-full' : 'hide-side-bar']"
      >
        <slot name="side" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import SideNavBar from '@/components/SideMenu/SideNavBar.vue';
import { useRouter } from 'vue-router';
import { routesNames } from '@/router';
import { computed, ref } from 'vue';

const currentRoute = computed(() => {
  return useRouter().currentRoute.value.name;
});
const emit = defineEmits(['update-isSideBarOpen']);

const props = defineProps<{
  isSideBarOpen: boolean;
}>();

const toggleSideMenu = () => {
  emit('update-isSideBarOpen', !props.isSideBarOpen);
};
</script>
<style scoped>
.side-menu-button {
  position: absolute;
  top: 140px;
  left: -65px;
  padding: 20px;
  border-radius: 10px 0 0 10px;
}

.flip-horizontally::before {
  transform: rotate(180deg);
}

.hide-side-bar {
  width: 0;
  overflow: hidden;
}
</style>
