<template>
  <div
    :class="{
      'scale-[1.02] border-l-2 border-primary-1 bg-light-2 dark:bg-dark-2':
        isSelected,
      'bg-light-1 dark:bg-dark-1': !isSelected,
    }"
    class="mt-5 flex items-center justify-between space-x-4 rounded-xl px-7 py-4 shadow hover:scale-[1.02] hover:dark:bg-dark-2"
  >
    <div
      v-if="props.order.workTypeCode"
      class="flex flex-col text-center text-xl font-semibold"
      :data-testId="`workTypeCode_${props.order.workTypeCode}`"
    >
      {{ props.order.workTypeCode }}
    </div>

    <div class="flex grow flex-col">
      <div class="flex flex-col">
        <div>{{ streetAndHouseNumber }}</div>
        <div>{{ zipCodeAndCity }}</div>
      </div>
    </div>

    <div
      class="flex flex-col items-center justify-center gap-1 py-3 py-5 md:flex-row md:gap-5"
    >
      <div
        v-if="props.order.status === OrderStatus.IN_PROGRESS"
        class="rounded-xl bg-light-warning px-2 py-1 text-center text-xs text-white dark:bg-transparent dark:text-yellow-500"
      >
        in Bearbeitung
      </div>
      <div class="text-center text-3xl font-semibold">{{ startTime }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Order } from '@/models/Order';
import { computed } from 'vue';
import { OrderStatus } from '@/models/types/OrderStatus';

const props = defineProps<{
  order: Order;
  isSelected: boolean;
}>();

const startTime = computed(() => {
  return new Date(props.order.appointment.start).toLocaleTimeString('de-DE', {
    hour: '2-digit',
    minute: '2-digit',
  });
});

const splitAddress = computed(() => {
  return props.order.address.split('\n');
});

const streetAndHouseNumber = computed(() => {
  return splitAddress.value[0] ?? '';
});

const zipCodeAndCity = computed(() => {
  const zipCode = splitAddress.value[1];
  const city = splitAddress.value[2];
  return zipCode ? zipCode + ' ' + city : city ?? '';
});
</script>
