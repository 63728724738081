import { createRouter, createWebHistory } from 'vue-router';
import MapView from '@/views/MapView.vue';
import ListView from '@/views/ListView.vue';
import DeviceManagement from '@/views/Funkcheck.vue';
import BuildingView from '@/views/BuildingView.vue';
import Login from '@/views/Login.vue';
import OrderManagement from '@/views/OrderManagement.vue';
import { useUserStore } from '@/store/user/userStore';
import { useLayoutStore } from '@/store/layout/layoutStore';
import LoRaWanRadioCheck from '@/views/LoRaWanRadioCheck.vue';
import { useEntitiesStore } from '@/store/entities/entitiesStore';

export const routesNames = {
  HOME: 'Home',
  BUSINESS_ENTITIES_LIST: 'BusinessEntitiesList',
  FUNKCHECK: 'Funkcheck',
  RADIO_CHECK: 'LoRaWANRadioCheck',
  BUILDING_OVERVIEW: 'Gebäudeübersicht',
  DETAIL_VIEW: 'Detailansicht',
  LOGIN: 'Login',
};

const routes = [
  {
    path: '/list',
    name: 'BusinessEntitiesList',
    component: ListView,
  },
  {
    path: '/',
    name: 'Home',
    component: OrderManagement,
    beforeEnter: () => {
      useEntitiesStore().resetActiveBusinessEntity();
    },
  },
  {
    path: '/wirtschaftseinheiten',
    name: 'Wirtschaftseinheiten',
    component: ListView,
    meta: { supportsDarkMode: true },
  },
  {
    path: '/detailansicht',
    name: 'Detailansicht',
    component: MapView,
    children: [
      {
        name: 'roomGroup',
        path: 'roomGroup',
        component: { template: '<router-view></router-view>' },
        children: [
          {
            name: 'room',
            path: 'room',
            component: { template: '<router-view></router-view>' },
          },
        ],
      },
    ],
  },
  {
    path: '/funkcheck',
    name: 'Funkcheck',
    component: DeviceManagement,
  },
  {
    path: '/gebaeudeuebersicht',
    name: 'Gebäudeübersicht',
    supportDarkMode: true,
    component: BuildingView,
  },
  {
    path: '/user',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false, supportsDarkMode: false },
  },
  {
    path: '/radioCheck',
    name: 'LoRaWANRadioCheck',
    component: LoRaWanRadioCheck,
  },
];

const router = createRouter({
  linkActiveClass: 'active',
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 };
  },
  routes,
});

router.beforeEach(async (to) => {
  const layoutStore = useLayoutStore();
  layoutStore.setRoute(to.name);
  layoutStore.closeSideMenu();
  if (to.meta.requiresAuth !== false) {
    const userStore = useUserStore();
    const currentUser = await userStore.fetchCurrentUser();
    if (currentUser) {
      userStore.setUser(currentUser);
    }
    if (!currentUser) return '/user';
  }
  if (to.meta.supportsDarkMode === false) {
    layoutStore.disableDarkModeTemporarily();
    return true;
  }
  if (layoutStore.isDarkMode()) {
    layoutStore.activateDarkMode();
    return true;
  }
});

export default router;
