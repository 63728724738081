<template>
  <div>
    <button
      :class="[
        'rounded-full p-6 py-2 text-white transition-all ',
        { 'is-loading': isSaving },
        { 'rounded-r pr-2': saved.status },
        { 'is-justify-content-start': saved.status && hasActiveOrder },
        {
          'bg-primary-2 dark:bg-primary-1 hover:dark:bg-primary-2':
            !saved.error,
        },
        {
          'dark:bg-dark-error-0 hover:dark:bg-dark-error-0 bg-light-error/10 hover:bg-light-error':
            saved.error,
        },
      ]"
      data-testid="uploadButton"
      @click="startSaveProcess"
    >
      <em
        :class="[
          'mdi leading-none',
          { 'mdi-content-save-outline': !saved.status },
          { 'mdi-check': saved.status },
          { 'mdi-loading mdi-spin': isSaving },
        ]"
      ></em>

      <span v-if="!saved.status"> Daten speichern </span>
    </button>
    <button
      v-if="hasActiveOrder"
      :class="[
        'rounded-full px-6 py-2 transition-all duration-300 ease-in-out',
        {
          'ml-3 cursor-not-allowed bg-light-disabled-background dark:bg-dark-disabled-background':
            !saved.status,
        },
        {
          'rounded-l bg-primary-2 pl-2 text-white dark:bg-primary-1 hover:dark:bg-primary-2 ':
            saved.status,
        },
      ]"
      :disabled="!saved.status || !isOnline"
      data-testid="closeOrderBtn"
      @click="openDialog"
    >
      <span
        :class="[
          {
            'text-light-disabled-text dark:text-dark-disabled-text':
              !saved.status,
          },
        ]"
        >Auftrag abschließen</span
      >
    </button>
  </div>
  <BasePrompt
    :open="modalOpen"
    :close="toggleModal"
    :cancel="toggleModal"
    :proceed="finishOrder"
    title="Auftrag abschließen"
    question="Sind Sie sicher, dass Sie den Auftrag abschließen möchten?"
  />
  <ImageUploadHeader ref="imageUpload" />
</template>

<script lang="ts" setup>
import { usePersistenceStore } from '@/store/persistence/persistenceStore';
import { computed, ref } from 'vue';
import { useOrderStore } from '@/store/order/orderStore';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { useRouter } from 'vue-router';
import BasePrompt from '@/components/UI/Modal/BasePrompt.vue';
import ImageUploadHeader from '@/components/Header/ImageUploadHeader.vue';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { Notification } from '@/models';
import { useFormStore } from '@/store/form/formStore';

const persistenceStore = usePersistenceStore();
const orderStore = useOrderStore();
const notificationStore = useNotificationStore();
const router = useRouter();

const isSaving = ref(false);
const modalOpen = ref(false);

const isOnline = computed(() => useLayoutStore().isOnline);

const saved = computed(() => {
  return persistenceStore.saved;
});

const hasActiveOrder = computed(() => {
  return !!orderStore.activeOrderId;
});

const imageUpload = ref<null | { uploadImages: () => null }>(null);

const startSaveProcess = () => {
  if (!isOnline.value) {
    notificationStore.addNotification(
      new Notification(
        'Stell sicher, dass du mit dem Internet verbunden bist.',
        'Du bist offline!',
        'error'
      )
    );
    return;
  }

  isSaving.value = true;
  persistenceStore
    .saveData()
    .then(() => {
      isSaving.value = false;
      imageUpload?.value?.uploadImages();
    })
    .catch((error) => {
      console.error(error);
    });
};
const toggleModal = () => {
  modalOpen.value = !modalOpen.value;
};

const isOnSiteInspection = computed(() => {
  return useOrderStore().isOnSiteInspection();
});

const openDialog = () => {
  return isOnSiteInspection.value ? openOrderForm() : toggleModal();
};

const openOrderForm = () => {
  const layoutStore = useLayoutStore();
  layoutStore.openFormDialog();

  useFormStore().setCurrentFormByName('Order', false);
};

const finishOrder = () => {
  useLayoutStore().setLoadingState(true);
  orderStore
    .finishOrder()
    ?.then(() => {
      useLayoutStore().resolveLoaderState(
        'Auftrag erfolgreich abgeschlossen...',
        true
      );
      setTimeout(() => {
        router.push({ name: 'Home' });
      }, 1500);
    })
    .catch(() => {
      useLayoutStore().resolveLoaderState(
        'Etwas ist schief gelaufen...',
        false
      );
      setTimeout(() => {
        useLayoutStore().setLoadingState(false);
        isSaving.value = false;
        toggleModal();
      }, 1500);
    });
};
</script>
