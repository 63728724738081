import { useEntitiesStore } from '@/store/entities/entitiesStore';
import { Notification } from '@/models/Notification';
import {
  putBusinessEntity,
  putRawBusinessEntity,
} from '@/api/businessEntity/BusinessEntityApi';
import { convertTreeToFlat } from '@/utils/transformers';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { Pinia, Store } from '@/store/pinia-class-component';
import { putImage } from '@/api/pictures/PicturesApi';
import { putOrderProgress } from '@/api/order/OrderManagement';
import { useOrderStore } from '@/store/order/orderStore';
import { Image } from '@/models/Image';

type SaveStatus = {
  timestamp: string | undefined;
  status: boolean;
  error: boolean;
};

export function usePersistenceStore() {
  return new PersistenceStore();
}

@Store
export class PersistenceStore extends Pinia {
  private _saved: SaveStatus;

  getStores() {
    const entitiesStore = useEntitiesStore();
    const notificationStore = useNotificationStore();
    const orderStore = useOrderStore();

    return {
      entitiesStore,
      notificationStore,
      orderStore,
    };
  }

  constructor() {
    super();
    this._saved = {
      timestamp: undefined,
      status: true,
      error: false,
    };
  }

  get saved(): SaveStatus {
    return this._saved;
  }

  updateSaveSuccess() {
    this._saved.error = false;
    this._saved.status = true;
    this._saved.timestamp = new Date().toISOString();
    useOrderStore().updateLastSavedOnActiveOrder(this._saved.timestamp);
  }

  updateSaveFail() {
    this._saved.error = true;
    this._saved.status = false;
    this._saved.timestamp = new Date().toISOString();
  }

  hasUnsavedOrderChanges() {
    const savedTimestamp = this._saved.timestamp as string;
    const orderChanges = this.getStores().orderStore.getOrderChanges();
    if (orderChanges) {
      const transactions = Object.values(orderChanges.transactions);
      return transactions.some((transaction) => {
        return transaction.timestamp > savedTimestamp;
      });
    }
    return false;
  }

  setSavedTimestampFromOrder(timestamp?: string) {
    this._saved.timestamp = timestamp;
    this.setSavedStatus();
  }

  setSavedStatus() {
    if (!this._saved.timestamp) {
      this._saved.timestamp = new Date().toISOString();
    }
    this._saved.status = !this.hasUnsavedOrderChanges();
  }

  async saveData() {
    const dataMap = convertTreeToFlat(
      this.getStores().entitiesStore.businessEntityMap
    );
    const rawDataMap = this.getStores().entitiesStore.businessEntityMap;

    // Send Backup to S3
    putRawBusinessEntity(rawDataMap).catch((error) => {
      this.updateSaveFail();
      const notification = new Notification(
        'Rohdaten konnten nicht im S3-Bucket gespeichert werden.',
        'Rohdaten Speichern fehlgeschlagen',
        'error',
        10000
      );

      this.getStores().notificationStore.addNotification(notification);
      console.error(`Raw Save failed  ${error}`);
    });
    const selectedOrder = this.getStores().orderStore?.selectedOrder;

    // Send BusinessEntity to backend
    await putBusinessEntity(dataMap, selectedOrder?.workTypeCode)
      .then(() => {
        this.updateSaveSuccess();
        const notification = new Notification(
          'Sie können weitere Daten ändern oder den Auftrag nun abschließen.',
          'Die Daten wurden gespeichert.',
          'info',
          8000
        );
        this.getStores().notificationStore.addNotification(notification);
      })
      .catch((error) => {
        this.updateSaveFail();
        const notification = new Notification(
          'Daten konnten nicht gespeichert werden.',
          'Speichern fehlgeschlagen',
          'error',
          10000
        );

        this.getStores().notificationStore.addNotification(notification);
        console.error(`Save failed ${error}`);
      });

    // Save Order Progress
    if (!selectedOrder) return;
    await putOrderProgress(selectedOrder).catch((error) => {
      console.error(`Save failed ${error}`);
    });
  }

  async saveImage(image: Image) {
    return await putImage(image);
  }
}
