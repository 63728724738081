import { useOrderStore } from '@/store/order/orderStore';
import { generateUniqueId } from '@/helpers/uuidUtils';

export class Image {
  public id: string;
  public businessEntityId: string;
  public orderId?: string;
  public objectId?: string;
  public objectType?: ImageObjectType | null;
  public image: File | string;
  public mimetype: string | undefined;
  public note?: string;
  public keywords?: string[];

  constructor() {
    this.id = generateUniqueId();
    this.businessEntityId = '';
    this.orderId = '';
    this.objectId = '';
    this.objectType = null;
    this.image = '';
    this.mimetype = '';
    this.note = '';
    this.keywords = [];
  }

  getImage() {
    return `data:image/png;base64,${this.image}`;
  }

  extractImageMimeType(imagePayload: string) {
    // data:image/png;base64,iVBORw0KGgo... -> image/png
    return imagePayload.split(',')[0].split(':')[1].split(';')[0];
  }

  assignImageProperties(
    objectId: string,
    objectType: ImageObjectType,
    imagePayload: string,
    businessEntityId: string
  ) {
    this.objectId = objectId;
    this.objectType = objectType;
    this.image = imagePayload.split(',')[1];
    this.mimetype = this.extractImageMimeType(imagePayload);
    this.businessEntityId = businessEntityId;
    this.orderId = useOrderStore().activeOrderId;
  }
}

export enum ImageObjectType {
  HKV = 'HKV',
  UNMOUNTED_DEVICE = 'UNMOUNTED_DEVICE',
  VALVE = 'VALVE',
  GATEWAY_LOCATION = 'GATEWAY_LOCATION',
  BUILDING = 'BUILDING',
  INSPECTED_BUILDING = 'INSPECTED_BUILDING',
  ORDER = 'ORDER',
}
