import { Entity } from '@/models/types/Entity';
import { BusinessEntity, Notification, Room, Roomgroup } from '@/models';
import { apiErrorCodes, entityTypes } from '@/enums/generic';
import { useBusinessEntityStore } from '@/store/entities/businessEntityStore';
import { useRoomStore } from '@/store/entities/roomStore';
import { useRoomGroupStore } from '@/store/entities/roomGroupStore';
import { useDeviceStore } from '@/store/entities/deviceStore';
import { useBuildingStore } from '@/store/entities/buildingStore';
import {
  getAllBusinessEntities,
  getBusinessEntityById,
} from '@/api/businessEntity/BusinessEntityApi';
import classTransformer from '@/utils/transformers/classTransformer';
import { useRadiatorStore } from '@/store/entities/radiatorStore';
import { getAllEntitiesByCriteria } from '@/store/entities/helper';
import { useNotificationStore } from '@/store/notifications/notificationStore';
import { useFormStore } from '@/store/form/formStore';
import { usePersistenceStore } from '@/store/persistence/persistenceStore';
import { usageTypeList } from '@/enums/roomgroup';
import { Pinia, Store } from '@/store/pinia-class-component';
import { useLayoutStore } from '@/store/layout/layoutStore';
import { miniEntityStore } from '@/store/entities/types/miniEntityStore';
import { LocalDb } from '@/native/plugins/LocalDb';
import { useInstallationPointStore } from '@/store/entities/installationPointStore';
import { useOrderStore } from '@/store/order/orderStore';
import { useImagesStore } from '@/store/images/imageStore';
import { usePlantStore } from '@/store/entities/plantStore';
import { Form } from '@/models/Form';
import { useInspectedBuildingStore } from '@/store/entities/inspectedBuildingStore';

type id = string;
type BusinessEntityMap = Map<id, Entity>;

export function useEntitiesStore() {
  return new EntitiesStore();
}

@Store
export class EntitiesStore extends Pinia {
  private _activeBusinessEntityId?: id;
  private _businessEntities: Map<id, BusinessEntityMap>;

  constructor() {
    super();
    this._activeBusinessEntityId = undefined;
    this._businessEntities = new Map();
  }

  get activeBusinessEntityId(): id | undefined {
    return this._activeBusinessEntityId;
  }

  get activeBusinessEntity(): BusinessEntity | undefined {
    if (!this._activeBusinessEntityId) {
      return;
    }

    return this._businessEntities
      .get(this._activeBusinessEntityId)
      ?.values()
      ?.next().value;
  }

  getBusinessEntityById(businessEntityId: id): BusinessEntityMap | undefined {
    return this._businessEntities.get(businessEntityId);
  }

  get businessEntities(): Map<id, BusinessEntityMap> {
    return this._businessEntities;
  }

  get businessEntityMap() {
    const businessEntity = new Map();
    businessEntity.set(
      this.getStores().businessEntityStore.businessEntity?.id,
      this.getStores().businessEntityStore.businessEntity
    );

    const array = [
      ...businessEntity,
      ...this.getStores().buildingStore.buildings,
      ...this.getStores().inspectedBuildingStore.inspectedBuildings,
      ...this.getStores().roomGroupStore.roomGroups,
      ...this.getStores().roomStore.rooms,
      ...this.getStores().deviceStore.devices,
      ...this.getStores().radiatorStore.radiators,
      ...this.getStores().installationPointStore.installationPoints,
      ...this.getStores().plantStore.plants,
    ];

    return new Map(array);
  }

  hasBusinessEntity(businessEntityId: id): boolean {
    const businessEntity = this.getBusinessEntityById(businessEntityId);
    return businessEntity !== undefined && businessEntity.size > 1;
  }

  fetchAllBusinessEntities() {
    return getAllBusinessEntities()
      .then(async (resp) => {
        for (const entity of resp.data) {
          this.setBusinessEntityList(
            classTransformer.plainToClassEntity(entity)
          );
        }
        return resp;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  resetActiveBusinessEntity() {
    this._activeBusinessEntityId = undefined;
  }

  setActiveBusinessEntityById(businessEntityId: id) {
    const layoutStore = useLayoutStore();
    layoutStore.setLoadingState(true);
    const notificationStore = useNotificationStore();
    this._activeBusinessEntityId = businessEntityId;
    return this.loadBusinessEntity(businessEntityId)
      .then((businessEntityMap: any) => {
        this.setBusinessEntityList(businessEntityMap);
        this.resetMiniEntityStores();
        this.setBusinessEntityMap(businessEntityMap);
        layoutStore.setLoadingState(false);
      })
      .catch((error) => {
        notificationStore.addNotification(
          new Notification(
            'Stell sicher, dass du mit dem Internet verbunden bist.',
            'Du bist offline!',
            'error'
          )
        );
        layoutStore.setLoadingState(false);
        return Promise.reject(error);
      });
  }

  async loadBusinessEntity(businessEntityId: id): Promise<BusinessEntityMap> {
    const layoutStore = useLayoutStore();
    const fromStore = this.getBusinessEntityById(businessEntityId);

    if (layoutStore.isOnline) {
      await this.getStores().imagesStore.getImagesConfirmation(
        businessEntityId
      );
    }

    try {
      if (fromStore && fromStore.size > 1) {
        console.debug('loadBusinessEntity: from store');
        return Promise.resolve(fromStore);
      }

      const fromLocalStorage = await this.loadBusinessEntityFromLocalStorage(
        businessEntityId
      );

      if (fromLocalStorage && fromLocalStorage.size > 1) {
        console.debug('loadBusinessEntity: from local storage');
        return Promise.resolve(fromLocalStorage);
      }
    } catch (error) {
      console.debug(error);
      console.debug("Local might be corrupted, let's try to fetch from server");
    }

    if (layoutStore.isOnline) {
      const fromAPI = await this.loadBusinessEntityFromAPI(businessEntityId);

      LocalDb.persistBusinessEntity({
        businessEntity: JSON.parse(JSON.stringify(fromAPI)),
      });

      const businessEntityMap: any[string][any] =
        classTransformer.plainToClassEntity(fromAPI);

      if (businessEntityMap) {
        return businessEntityMap;
      } else {
        console.debug('loadBusinessEntity: no data found');
        return Promise.reject(new Error('No data found'));
      }
    } else {
      return Promise.reject(new Error('No internet connection'));
    }
  }

  async loadBusinessEntityFromAPI(businessEntityId: id): Promise<any> {
    console.info('loadBusinessEntity: from api');
    return getBusinessEntityById(businessEntityId).then((resp) => {
      if (resp.data.code) {
        this.getStores().layoutStore.setLoadingState(false);
        const code: keyof typeof apiErrorCodes = resp.data.code;
        const apiErrorCode = apiErrorCodes[code];
        const notification = new Notification()
          .setType('error')
          .setText(apiErrorCode);

        this.getStores().notificationStore.addNotification(notification);
        throw new Error(resp.data.code);
      }

      return resp.data;
    });
  }

  async loadBusinessEntityFromLocalStorage(
    businessEntityId: id
  ): Promise<BusinessEntityMap | undefined> {
    const localStorageBusinessEntity = await this.loadFromLocalStorage(
      businessEntityId
    );

    if (!localStorageBusinessEntity) {
      return undefined;
    }

    return classTransformer.plainToClassEntity(localStorageBusinessEntity);
  }

  async loadFromLocalStorage(businessEntityId: any) {
    const businessEntityJSON = await LocalDb.loadBusinessEntity({
      id: businessEntityId,
    });

    if (typeof businessEntityJSON.businessEntity === 'string') {
      return JSON.parse(businessEntityJSON.businessEntity);
    }

    return businessEntityJSON.businessEntity;
  }

  setBusinessEntityList(businessEntityMap: BusinessEntityMap) {
    const businessEntity = businessEntityMap.values().next().value;
    if (!(businessEntity instanceof BusinessEntity)) {
      console.error(
        'setBusinessEntityList: First entity is not of type BusinessEntity'
      );
    }
    this._businessEntities.set(businessEntity.id, businessEntityMap);
  }

  removeBusinessEntityFromList(businessEntityId: id) {
    this._businessEntities.delete(businessEntityId);
  }

  setBusinessEntityMap(newBusinessEntityMap: BusinessEntityMap) {
    newBusinessEntityMap.forEach((entity: Entity) => {
      const store = this.getStores().storeList[entity.type];
      store.addEntity(entity);
    });
  }

  /*
   * Aims to sync entity map with businessEntities Array
   * to persist in localstorage
   */
  syncBusinessEntities() {
    if (
      this._activeBusinessEntityId &&
      this._businessEntities.get(this._activeBusinessEntityId)
    ) {
      const businessEntity = this.businessEntityMap;
      this._businessEntities.set(this._activeBusinessEntityId, businessEntity);
    }
  }

  getStores() {
    const layoutStore = useLayoutStore();
    const businessEntityStore = useBusinessEntityStore();
    const roomStore = useRoomStore();
    const roomGroupStore = useRoomGroupStore();
    const deviceStore = useDeviceStore();
    const buildingStore = useBuildingStore();
    const inspectedBuildingStore = useInspectedBuildingStore();
    const radiatorStore = useRadiatorStore();
    const notificationStore = useNotificationStore();
    const formStore = useFormStore();
    const imagesStore = useImagesStore();
    const installationPointStore = useInstallationPointStore();
    const plantStore = usePlantStore();
    const orderStore = useOrderStore();

    const storeList: any = {
      businessEntity: businessEntityStore,
      room: roomStore,
      roomgroup: roomGroupStore,
      device: deviceStore,
      building: buildingStore,
      inspectedBuilding: inspectedBuildingStore,
      radiator: radiatorStore,
      plant: plantStore,
      installationPoint: installationPointStore,
    };

    return {
      businessEntityStore,
      roomStore,
      roomGroupStore,
      buildingStore,
      inspectedBuildingStore,
      deviceStore,
      radiatorStore,
      storeList,
      plantStore,
      layoutStore,
      notificationStore,
      formStore,
      imagesStore,
      installationPointStore,
      orderStore,
    };
  }

  deleteEntityByType(entity: Entity) {
    const store = this.getStores().storeList[entity.type];
    if (store && store.deleteEntity !== undefined) {
      store.deleteEntity(entity);
      LocalDb.deleteEntity({
        entity,
        businessEntityId: this._activeBusinessEntityId,
      });
    }
  }

  changeSaveStatus() {
    usePersistenceStore().setSavedStatus();
  }

  getEntityById(id: string) {
    const entity = this.businessEntityMap.get(id);
    if (!entity) {
      throw new Error('Entity not found');
    }
    return entity;
  }

  hasEntityById(id: string): boolean {
    return this.businessEntityMap.has(id);
  }

  getAllEntitiesByParentId(id: string) {
    const result: Array<Entity> = [];
    this.businessEntityMap.forEach((value) => {
      if (value.getParentId && value.getParentId() === id) {
        result.push(value);
      }
    });
    return result;
  }

  getAllEntitiesByCriteria(predicate: any) {
    return getAllEntitiesByCriteria(this.businessEntityMap, predicate);
  }

  getParentOfEntity(id: string) {
    const entity = this.getEntityById(id) as Entity;
    if (entity?.type === entityTypes.businessEntity) {
      return undefined;
    }

    if (entity.type === entityTypes.installationPoint) {
      return this.getEntityById(entity.getParentId() as string);
    }

    if (entity.type === entityTypes.roomgroup) {
      const firstRoom = this.getStores().roomStore.getFirstRoomOfRoomgroup(
        entity.id as string
      );

      const buildingId = this.getStores().buildingStore.buildingId;
      const hasParentRoomId = firstRoom?.parentRoomId;
      if (!hasParentRoomId && buildingId) {
        return this.getEntityById(buildingId);
      }
      return this.getEntityById(firstRoom?.getParentId() as string);
    }

    const parent = this.getEntityById(entity.getParentId() as string);
    if (parent?.type === entityTypes.roomgroup) {
      return this.getEntityById(parent.getParentId() as string);
    }
    return this.getEntityById(entity.getParentId() as string);
  }

  getSiblings(entity: Entity) {
    if (entity.type === entityTypes.roomgroup && entity instanceof Roomgroup) {
      const firstRoom = this.getStores().roomStore.getFirstRoomOfRoomgroup(
        entity.id
      );

      const rooms = this.getAllEntitiesByCriteria(
        (node: any) =>
          node.type === entityTypes.room &&
          node.floorLevel.type === firstRoom?.floorLevel.type &&
          node.floorLevel.level === firstRoom?.floorLevel.level &&
          node.roomGroupId !== entity.id
      );

      const roomGroups: Array<Roomgroup> = [];

      rooms.forEach((room) => {
        const roomGroup = this.getEntityById(room.roomGroupId) as Roomgroup;
        if (
          roomGroup.usageType !== usageTypeList[2].value &&
          roomGroup.buildingId === entity.buildingId
        ) {
          roomGroups.push(roomGroup);
        }
      });

      return roomGroups;
    }

    if (entity.type === entityTypes.room && entity instanceof Room) {
      const roomParent = this.getParentOfEntity(entity.id) as Room;
      if (roomParent.roomGroupId !== entity.roomGroupId) {
        return [entity];
      }
    }

    const parent = this.getParentOfEntity(entity.id);

    return this.getAllEntitiesByCriteria(
      (node: any) =>
        node.getParentId() === parent?.id &&
        node.type === entity.type &&
        node.id !== entity.id
    );
  }

  deleteEntity(entity: Entity, notification?: Notification) {
    if (!entity.id) {
      throw new Error(`Cannot delete entity without a valid id`);
    }
    if (!this.getStores().formStore?.currentForm?.name?.includes('List')) {
      this.getStores().layoutStore.changeCurrentNode(
        this.getParentOfEntity(entity.id) as Entity
      );
    }

    if (notification) {
      this.getStores().notificationStore.addNotification(notification);
    }

    this.getStores().orderStore.handleDeleteEntityTransactions(entity);
    this.updateOrdinals(entity);
    this.deleteEntityByType(entity);
    this.changeSaveStatus();
    this.syncBusinessEntities();
  }

  saveEntity(entity: Entity, notification?: Notification, payload?: any) {
    if (!entity.id) {
      throw new Error(`Cannot save entity without a valid id`);
    }
    this.getStores().orderStore.handleSaveEntityTransactions(entity);

    const store = this.getStores().storeList[entity.type];
    store.addEntity(entity);
    const form = new Form()
      .setName(entity.type)
      .setId(entity.id)
      .setPayload(payload)
      .hasHistory(false);

    const parent = entity.getParentId();

    if (parent) {
      form.setParentId(parent);
    }

    this.getStores().formStore.setCurrentForm(form);
    this.getStores().formStore.setFormAsModified(false);

    if (notification) {
      this.getStores().notificationStore.addNotification(notification);
    }

    this.saveEntityToLocal(entity);
    this.syncBusinessEntities();
    this.changeSaveStatus();
  }

  saveEntityToLocal(entity: Entity) {
    LocalDb.persistEntity({
      entity,
      businessEntityId: this._activeBusinessEntityId,
    });
  }

  updateOrdinals(entity: Entity) {
    const siblings = this.getSiblings(entity);
    siblings.forEach((sibling, index) => {
      sibling.ordinal = index + 1;
      LocalDb.persistEntity({
        entity: sibling,
        businessEntityId: this._activeBusinessEntityId,
      });
    });

    this.syncBusinessEntities();
  }

  resetMiniEntityStores() {
    const miniEntityStores: Array<miniEntityStore> = Object.values(
      this.getStores().storeList
    );

    miniEntityStores.forEach((store) => {
      store.resetState();
    });
  }
}
