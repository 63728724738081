<template>
  <div
    class="prose prose-neutral flex max-w-none flex-col bg-light-0 dark:prose-invert dark:bg-dark-0 md:flex-row"
  >
    <div
      class="flex-grow-1 fixed z-40 w-full basis-20 bg-light-1 shadow dark:bg-dark-1 dark:text-white md:relative md:w-auto md:basis-12"
    >
      <SideNavBar />
    </div>
    <div
      class="h-screen overflow-y-auto bg-light-0 px-8 dark:bg-dark-0 md:grow md:basis-0"
    >
      <OrderOverview v-model:selectedOrder="selectedOrder"></OrderOverview>
    </div>
    <div
      class="fixed bottom-[-10rem] z-10 flex w-full justify-center rounded-3xl bg-light-1 align-middle transition-all dark:bg-dark-1 md:relative md:bottom-0 md:right-0 md:mt-0 md:rounded-none"
      :class="[selectedOrder ? 'h-screen w-full md:w-1/2' : 'h-0  md:w-0']"
    >
      <OrderDetails
        v-if="selectedOrder"
        v-model:selected-order="selectedOrder"
      ></OrderDetails>
    </div>
    <AppDownloadModal
      v-if="showAppDownloadDialog"
      @close-dialog="closeAppDownloadDialog()"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import OrderOverview from '@/components/OrderManagement/OrderOverview.vue';
import SideNavBar from '@/components/SideMenu/SideNavBar.vue';
import OrderDetails from '@/components/OrderManagement/OrderDetails.vue';
import AppDownloadModal from '@/components/OrderManagement/AppDownloadModal.vue';
import { useAppStore } from '@/store/mobile/AppStore';
import { WebEvents } from '@/native/plugins/WebEvents';
import { useLayoutStore } from '@/store/layout/layoutStore';

const selectedOrder = ref(undefined);
const appStore = useAppStore();
const layoutStore = useLayoutStore();

onMounted(() => {
  WebEvents.onLoggedIn();
});

const showAppDownloadDialog = computed(() => appStore.isDialogOpen);

const closeAppDownloadDialog = () => {
  appStore.toggleAppDownloadDialog(false);
};
</script>
