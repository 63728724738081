<template>
  <transition name="fade" appear>
    <div v-if="active" class="modal" :class="{ 'is-active': active }">
      <div class="modal-background" @click="closeModal()"></div>
      <div :class="`modal-card ${size}`">
        <header
          class="modal-card-head dark:border-b-0 dark:bg-dark-0 keyboard-open:hidden"
        >
          <p class="modal-card-title"><slot name="title"></slot></p>
          <button
            class="absolute right-4 top-4 ml-auto inline-flex items-center rounded-lg bg-transparent px-2 py-1.5 text-2xl text-black hover:bg-gray-200 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-dark-4 dark:hover:text-white"
            aria-label="close"
            data-testid="modalCloseBtn"
            @click="closeModal()"
          >
            <em class="mdi mdi-close" />
          </button>
        </header>
        <section class="modal-card-body dark:bg-dark-1">
          <slot name="default"></slot>
        </section>
        <footer
          v-if="$slots.buttons"
          class="modal-card-foot dark:border-t-0 dark:bg-dark-0 keyboard-open:hidden"
        >
          <slot name="buttons"></slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'BaseModal',

  props: {
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['update:active', 'closed'],

  methods: {
    closeModal() {
      this.$emit('update:active', false);
      this.$emit('closed');
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.button-wrapper {
  position: relative;
  margin-top: 20px;
}

.modal-card {
  &.is-big {
    width: 980px;
  }
}

.modal-card-foot {
  justify-content: flex-end;
}

.modal-container .modal-content {
  width: 80%;
  display: flex;
  justify-content: center;
}
</style>
