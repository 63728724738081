/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  deviceTypes,
  deviceTypeDetails,
  SRTHardwareData,
  MeasurementUnit,
  productKeyHCA,
  detectionTypes,
  LoRaWanHardwareData,
  DeviceTypeEnum,
} from '@/enums/device';
import { entityTypes } from '@/enums/generic';
import { generateUniqueId } from '@/helpers/uuidUtils';
import i18n from '@/utils/i18n/plantData.json';

/**
 * Any adjustments to the properties of this class
 * should be reflected in the android kotlin class {@see DeviceClasses.kt}
 */

type DeviceParams = {
  installationPointId: string;
  detectionType: string;
  serialNumber: string;
  productKey: string;
  isAutomatedMeterReading: boolean;
  note?: string;
  unknownProperties?: string;
  funkcheckStatus?: string;
};

export abstract class Device<T> {
  public id: string;
  public type: string = entityTypes.device;
  public deviceType: DeviceTypeEnum;
  public installationPointId: string;
  public detectionType: string;
  public serialNumber: string;
  public productKey: string;
  public isAutomatedMeterReading: boolean;
  public note?: string;
  public unknownProperties?: string;
  public funkcheckStatus?: string;

  protected constructor(params: any) {
    this.id = generateUniqueId();
    this.type = entityTypes.device;
    this.deviceType = params.deviceType;
    this.installationPointId = params.installationPointId ?? undefined;
    this.detectionType =
      params.detectionType ?? detectionTypes.ManuelleErfassung;
    this.serialNumber = params.serialNumber ?? undefined;
    this.productKey = params.productKey ?? undefined;
    this.isAutomatedMeterReading = params.isAutomatedMeterReading ?? true;
    this.note = params.note ?? undefined;
    this.unknownProperties = params.unknownProperties ?? undefined;
    this.funkcheckStatus = undefined;
  }

  updateProperties<T extends DeviceTypeParams>(formData: T) {
    const wrongProperties = Object.keys(formData).filter(
      (key) => !Object.keys(this).includes(key)
    );

    if (wrongProperties.length > 0) {
      throw new Error(
        `Provided wrong properties: ${wrongProperties} with ${JSON.stringify(
          formData
        )}`
      );
    }
    Object.assign(this, formData);
  }

  setInstallationPointId(installationPointId: string) {
    this.installationPointId = installationPointId;
  }

  getParentId(): string | undefined {
    return this.installationPointId;
  }

  getDeviceType() {
    const deviceType: any = Object.values(deviceTypeDetails).filter(
      (type) => type.value === this.deviceType
    )[0];
    return deviceType;
  }

  getLabel() {
    return this.getDeviceType().shortLabel;
  }

  getTagColor() {
    return this.getDeviceType().color;
  }

  getIcon() {
    return 'fa fa-mobile-alt';
  }

  isDeviceOnRadiator() {
    return (
      this.deviceType === deviceTypes.SmartRadiatorThermostat ||
      this.deviceType === deviceTypes.HeatCostAllocator
    );
  }

  isMeteringDevice() {
    return this instanceof MeteringDevice;
  }

  isMeasuringDevice() {
    return this instanceof MeasuringDevice;
  }

  isLegacyDevice() {
    return this instanceof LegacyMeteringDevice;
  }

  isHeatingPlantMeterDevice() {
    return this instanceof LegacyHeatingPlantMeter;
  }

  isWaterPlantMeterDevice() {
    return this instanceof LegacyWaterPlantMeter;
  }

  isLegacyPlantDevice() {
    return this.isHeatingPlantMeterDevice() || this.isWaterPlantMeterDevice();
  }
}

interface SmokeDetectorParams extends DeviceParams {
  // @ts-ignore
  deviceType: deviceTypes.SmokeDetector;
}

export class SmokeDetector extends Device<SmokeDetectorParams> {
  constructor() {
    const params = {
      deviceType: deviceTypes.SmokeDetector,
    };
    super(params);
  }
}

interface DirectMeterGatewayParams
  extends Omit<DeviceParams, 'isAutomatedMeterReading'> {
  // @ts-ignore
  deviceType: deviceTypes.DirectMeterGateway;
}

export class DirectMeterGateway extends Device<DirectMeterGatewayParams> {
  constructor() {
    const params = {
      deviceType: deviceTypes.DirectMeterGateway,
      isAutomatedMeterReading: false,
    };
    super(params);
  }
}

interface LoRaWanGatewayParams
  extends Omit<DeviceParams, 'isAutomatedMeterReading'> {
  // @ts-ignore
  deviceType: deviceTypes.LoRaWanGateway;
  eui: string;
  manufacturer: string;
}

export class LoRaWanGateway extends Device<LoRaWanGatewayParams> {
  public eui: string;
  public manufacturer: string;

  constructor() {
    const params = {
      deviceType: deviceTypes.LoRaWanGateway,
      productKey: undefined,
      isAutomatedMeterReading: false,
    };
    super(params);
    this.eui = '';
    this.manufacturer = LoRaWanHardwareData.MANUFACTURER;
  }
}

interface SmartRadiatorThermostatParams
  extends Omit<DeviceParams, 'isAutomatedMeterReading' | 'productKey'> {
  // @ts-ignore
  deviceType: deviceTypes.SmartRadiatorThermostat;
  serialNumberBatteryPack: string;
  articleNumberBatteryPack: string;
  counterPressure: number;
}

export class SmartRadiatorThermostat extends Device<SmartRadiatorThermostatParams> {
  public manufacturer: string;
  public serialNumberBatteryPack: string;
  public articleNumberBatteryPack: string;
  public counterPressure: number | undefined;
  public measurementUnit: string;
  constructor() {
    const params = {
      deviceType: deviceTypes.SmartRadiatorThermostat,
      productKey: SRTHardwareData.PRODUCT_KEY,
      isAutomatedMeterReading: true,
    };
    super(params);
    this.manufacturer = SRTHardwareData.MANUFACTURER;
    this.serialNumberBatteryPack = '';
    this.articleNumberBatteryPack = '42005';
    this.counterPressure = undefined;
    this.measurementUnit = MeasurementUnit.N.value;
  }
}

interface MeasuringDeviceParams extends DeviceParams {
  measurementUnit: string;
}

export abstract class MeasuringDevice<T> extends Device<MeasuringDeviceParams> {
  public measurementUnit: string | null;
  constructor(params: any) {
    super(params);
    this.measurementUnit = params.measurementUnit ?? null;
  }
}

interface MeteringDeviceParams extends MeasuringDeviceParams {
  yearOfConformity: number;
}

export abstract class MeteringDevice<
  T
> extends MeasuringDevice<MeteringDeviceParams> {
  public yearOfConformity?: number;

  constructor(params: any) {
    super(params);
    this.yearOfConformity = params.yearOfConformity;
  }
}

interface HeatCostAllocatorParams
  extends Omit<MeteringDeviceParams, 'productKey' | 'measurementUnit'> {
  // @ts-ignore
  deviceType: deviceTypes.HeatCostAllocator;
}

export class HeatCostAllocator extends MeteringDevice<HeatCostAllocatorParams> {
  constructor() {
    super({
      deviceType: deviceTypes.HeatCostAllocator,
      productKey: productKeyHCA,
      measurementUnit: MeasurementUnit.VE.value,
      detectionType: 'MANUAL',
    });
  }
}

interface HeatMeterParams extends MeteringDeviceParams {
  // @ts-ignore
  deviceType: deviceTypes.HeatMeter;
}
export class HeatMeter extends MeteringDevice<HeatMeterParams> {
  constructor() {
    super({
      deviceType: deviceTypes.HeatMeter,
    });
  }
}

interface WaterMeterParams
  extends Omit<MeteringDeviceParams, 'measurementUnit'> {
  // @ts-ignore
  deviceType: deviceTypes.WaterMeter;
}

export class WaterMeter extends MeteringDevice<WaterMeterParams> {
  constructor() {
    super({
      deviceType: deviceTypes.WaterMeter,
      measurementUnit: MeasurementUnit.M3.value,
    });
  }
}

interface WarmWaterMeterParams
  extends Omit<MeteringDeviceParams, 'measurementUnit'> {
  // @ts-ignore
  deviceType: deviceTypes.WarmWaterMeter;
}
export class WarmWaterMeter extends MeteringDevice<WarmWaterMeterParams> {
  constructor() {
    super({
      deviceType: deviceTypes.WarmWaterMeter,
      measurementUnit: MeasurementUnit.M3.value,
    });
  }
}

type LegacyMeterDeviceParams = Omit<
  MeteringDeviceParams,
  'detectionType' | 'isAutomatedMeterReading'
>;

export abstract class LegacyMeteringDevice<
  T
> extends MeteringDevice<LegacyMeterDeviceParams> {
  public yearOfConformity?: number;
  constructor(superParams: any) {
    const params = {
      ...superParams,
      detectionType: detectionTypes.ManuelleErfassung,
      isAutomatedMeterReading: false,
    };
    super(params);
    this.yearOfConformity = params.yearOfConformity ?? undefined;
  }
}

interface LegacyHeatEnergyMeterParams extends LegacyMeterDeviceParams {
  // @ts-ignore
  deviceType: deviceTypes.LegacyHeatMeter;
}

export class LegacyHeatEnergyMeter extends LegacyMeteringDevice<LegacyHeatEnergyMeterParams> {
  constructor() {
    super({
      deviceType: deviceTypes.LegacyHeatMeter,
    });
  }
}

interface LegacyWaterMeterParams
  extends Omit<LegacyMeterDeviceParams, 'measurementUnit'> {
  // @ts-ignore
  deviceType: deviceTypes.LegacyWaterMeter;
}
export class LegacyWaterMeter extends LegacyMeteringDevice<LegacyWaterMeterParams> {
  constructor() {
    super({
      deviceType: deviceTypes.LegacyWaterMeter,
      measurementUnit: MeasurementUnit.M3.value,
    });
  }
}

interface LegacyWarmWaterMeterParams
  extends Omit<LegacyMeterDeviceParams, 'measurementUnit'> {
  // @ts-ignore
  deviceType: deviceTypes.LegacyWarmWaterMeter;
}

export class LegacyWarmWaterMeter extends LegacyMeteringDevice<LegacyWarmWaterMeterParams> {
  constructor() {
    super({
      deviceType: deviceTypes.LegacyWarmWaterMeter,
      measurementUnit: MeasurementUnit.M3.value,
    });
  }
}

interface LegacyHeatingPlantMeterParams
  extends Omit<LegacyMeterDeviceParams, 'productKey' | 'funkcheckStatus'> {
  // @ts-ignore
  deviceType: deviceTypes.LegacyHeatingPlantMeter;
  energySource?: EnergySource;
  isPrimary: boolean;
}

export class LegacyHeatingPlantMeter extends LegacyMeteringDevice<LegacyHeatingPlantMeterParams> {
  public energySource?: string;
  public isPrimary: boolean;

  constructor() {
    const params = {
      deviceType: deviceTypes.LegacyHeatingPlantMeter,
      detectionType: detectionTypes.ManuelleErfassung,
      isAutomatedMeterReading: false,
      productKey: undefined,
      funkcheckStatus: undefined,
    };
    super(params);
    this.energySource = i18n.energySourceOptions[0].value;
    this.isPrimary = true;
  }
}

interface LegacyWaterPlantMeterParams
  extends Omit<LegacyMeterDeviceParams, 'productKey' | 'funkcheckStatus'> {
  // @ts-ignore
  deviceType: deviceTypes.LegacyWaterPlantMeter;
}

export class LegacyWaterPlantMeter extends LegacyMeteringDevice<LegacyWaterPlantMeterParams> {
  constructor() {
    const params = {
      deviceType: deviceTypes.LegacyWaterPlantMeter,
      detectionType: detectionTypes.ManuelleErfassung,
      isAutomatedMeterReading: false,
      productKey: undefined,
      funkcheckStatus: undefined,
    };
    super(params);
  }
}

type EnergySource =
  | 'NATURAL_GAS_H'
  | 'NATURAL_GAS_L'
  | 'LIQUEFIED_PETROLEUM_GAS'
  | 'TOWN_GAS'
  | 'BIOGAS_METHANE'
  | 'LIGHT_HEATING_OIL'
  | 'BIO_OIL_BIO_DIESEL'
  | 'WOOD'
  | 'WOOD_CHIPS'
  | 'WOOD_PELLETS'
  | 'COKE'
  | 'HARD_COAL'
  | 'LIGNITE'
  | 'DISTRICT_HEATING_PLANT'
  | 'COMBINED_HEAT_AND_POWER_PLANT'
  | 'ELECTRICITY'
  | 'GEOTHERMAL_ENERGY'
  | 'AMBIENT_HEAT'
  | 'SOLAR_POWER';

export type DeviceTypeParams =
  | SmokeDetectorParams
  | DirectMeterGatewayParams
  | SmartRadiatorThermostatParams
  | LoRaWanGatewayParams
  | HeatCostAllocatorParams
  | HeatMeterParams
  | WarmWaterMeterParams
  | WaterMeterParams
  | LegacyHeatEnergyMeterParams
  | LegacyWarmWaterMeterParams
  | LegacyWaterMeterParams
  | LegacyHeatingPlantMeterParams
  | LegacyWaterPlantMeterParams;

export type DeviceClasses =
  | SmokeDetector
  | DirectMeterGateway
  | LoRaWanGateway
  | SmartRadiatorThermostat
  | HeatCostAllocator
  | HeatMeter
  | WarmWaterMeter
  | WaterMeter
  | LegacyHeatEnergyMeter
  | LegacyWarmWaterMeter
  | LegacyWaterMeter
  | LegacyHeatingPlantMeter
  | LegacyWaterPlantMeter;

export type RadiatorDevice = SmartRadiatorThermostat | HeatCostAllocator;

export type LegacyPlantMeterDevice =
  | LegacyHeatingPlantMeter
  | LegacyWaterPlantMeter;

export type DeviceType =
  | 'SMOKE_DETECTOR'
  | 'HEAT_ENERGY_METER'
  | 'WARM_WATER_METER'
  | 'WATER_METER'
  | 'DIRECT_METER_GATEWAY'
  | 'HEAT_COST_ALLOCATOR'
  | 'SMART_RADIATOR_THERMOSTAT'
  | 'LO_RA_WAN_GATEWAY'
  | 'LEGACY_HEAT_ENERGY_METER'
  | 'LEGACY_WARM_WATER_METER'
  | 'LEGACY_WATER_METER'
  | 'LEGACY_HEATING_PLANT_METER'
  | 'LEGACY_WATER_PLANT_METER';

type DeviceClassMap = {
  SMOKE_DETECTOR: SmokeDetector;
  HEAT_ENERGY_METER: HeatMeter;
  WARM_WATER_METER: WarmWaterMeter;
  WATER_METER: WaterMeter;
  DIRECT_METER_GATEWAY: DirectMeterGateway;
  HEAT_COST_ALLOCATOR: HeatCostAllocator;
  SMART_RADIATOR_THERMOSTAT: SmartRadiatorThermostat;
  LO_RA_WAN_GATEWAY: LoRaWanGateway;
  LEGACY_HEAT_ENERGY_METER: LegacyHeatEnergyMeter;
  LEGACY_WARM_WATER_METER: LegacyWarmWaterMeter;
  LEGACY_WATER_METER: LegacyWaterMeter;
  LEGACY_HEATING_PLANT_METER: LegacyHeatingPlantMeter;
  LEGACY_WATER_PLANT_METER: LegacyWaterPlantMeter;
};

export class DeviceFactory {
  static createDevice<T extends DeviceType>(type: T): DeviceClassMap[T] {
    switch (type) {
      case deviceTypes.SmokeDetector:
        return new SmokeDetector() as DeviceClassMap[T];
      case deviceTypes.DirectMeterGateway:
        return new DirectMeterGateway() as DeviceClassMap[T];
      case deviceTypes.LoRaWanGateway:
        return new LoRaWanGateway() as DeviceClassMap[T];
      case deviceTypes.SmartRadiatorThermostat:
        return new SmartRadiatorThermostat() as DeviceClassMap[T];
      case deviceTypes.HeatCostAllocator:
        return new HeatCostAllocator() as DeviceClassMap[T];
      case deviceTypes.HeatMeter:
        return new HeatMeter() as DeviceClassMap[T];
      case deviceTypes.WarmWaterMeter:
        return new WarmWaterMeter() as DeviceClassMap[T];
      case deviceTypes.WaterMeter:
        return new WaterMeter() as DeviceClassMap[T];
      case deviceTypes.LegacyWaterMeter:
        return new LegacyWaterMeter() as DeviceClassMap[T];
      case deviceTypes.LegacyWarmWaterMeter:
        return new LegacyWarmWaterMeter() as DeviceClassMap[T];
      case deviceTypes.LegacyHeatMeter:
        return new LegacyHeatEnergyMeter() as DeviceClassMap[T];
      case deviceTypes.LegacyHeatingPlantMeter:
        return new LegacyHeatingPlantMeter() as DeviceClassMap[T];
      case deviceTypes.LegacyWaterPlantMeter:
        return new LegacyWaterPlantMeter() as DeviceClassMap[T];
      default:
        throw new Error(`Unsupported device type: ${type}`);
    }
  }
}

export const isMeteringDevice = (deviceType: DeviceType) => {
  return (
    deviceType &&
    [
      deviceTypes.HeatMeter,
      deviceTypes.WaterMeter,
      deviceTypes.WarmWaterMeter,
      deviceTypes.LegacyWarmWaterMeter,
      deviceTypes.LegacyWaterMeter,
      deviceTypes.LegacyHeatMeter,
      deviceTypes.HeatCostAllocator,
      deviceTypes.LegacyHeatingPlantMeter,
      deviceTypes.LegacyWaterPlantMeter,
    ].includes(deviceType)
  );
};

export const isMeasuringDevice = (deviceType: DeviceType) => {
  return (
    deviceType &&
    [
      deviceTypes.HeatMeter,
      deviceTypes.WaterMeter,
      deviceTypes.WarmWaterMeter,
      deviceTypes.LegacyWarmWaterMeter,
      deviceTypes.LegacyWaterMeter,
      deviceTypes.LegacyHeatMeter,
      deviceTypes.HeatCostAllocator,
      deviceTypes.LegacyHeatingPlantMeter,
      deviceTypes.LegacyWaterPlantMeter,
    ].includes(deviceType)
  );
};

export const isLegacyDevice = (deviceType: DeviceType) => {
  return (
    deviceType &&
    [
      deviceTypes.LegacyWarmWaterMeter,
      deviceTypes.LegacyWaterMeter,
      deviceTypes.LegacyHeatMeter,
      deviceTypes.LegacyHeatingPlantMeter,
      deviceTypes.LegacyWaterPlantMeter,
    ].includes(deviceType)
  );
};

export const isLegacyPlantMeterDevice = (deviceType: DeviceType) => {
  return (
    deviceType &&
    [
      deviceTypes.LegacyHeatingPlantMeter,
      deviceTypes.LegacyWaterPlantMeter,
    ].includes(deviceType)
  );
};
